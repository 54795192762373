.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 600px;
  height: 100vh;
  padding: 2rem;
  background-color: white;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.poopers,
.duration {
  display: flex;
  flex-direction: column;
  margin-top: -300px;
  transition: margin-top 300ms;
  width: 40%;
}
.poopers {
  align-items: flex-start;
}
.duration {
  align-items: flex-end;
}
.infoTitle {
  margin-bottom: 0.5rem;
}
.infoData {
  font-size: 2rem;
}
.poopers.pressed,
.duration.pressed {
  margin-top: 0;
}

.body {
  flex: 1;
}
.instructions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
}
.subinstructions {
  margin-top: 1rem;
  font-size: 0.5rem;
}
