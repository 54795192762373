.poop,
.poop * {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color;
  font-size: 0.75rem;
}
.poop * {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.poop {
  width: 175px;
  height: 175px;
  background-color: rgb(106, 76, 17);
  transition-delay: 75ms;
}
.poop1 {
  background-color: rgb(128, 91, 16);
  transition-delay: 50ms;
}
.poop2 {
  background-color: rgb(142, 99, 12);
  transition-delay: 25ms;
}
.poop3 {
  background-color: rgb(157, 108, 10);
  color: rgb(116, 79, 6);
}

.poop.pressed {
  background-color: rgb(157, 108, 10);
}
.poop1.pressed {
  background-color: rgb(142, 99, 12);
}
.poop2.pressed {
  background-color: rgb(128, 91, 16);
}
.poop3.pressed {
  background-color: rgb(106, 76, 17);
  color: rgb(164, 112, 8);
}
